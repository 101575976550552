import { useAppFrame } from "@appframe/base/hooks/useAppFrame"
import { CreateTenantArgs, createTenant } from "../createTenant";
import { GetTenantsArgs, getTenants } from "../getTenants";

export const useTenantsModule = () => {
    const { getUrl } = useAppFrame();

    return {
        createTenant: (args: CreateTenantArgs) => createTenant(getUrl, args),
        getTenants: (args: GetTenantsArgs) => getTenants(getUrl, args)
    }
}