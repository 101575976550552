import { Button, Modal } from "antd"
import { FileAddOutlined } from '@ant-design/icons';
import { NewEnvironmentModal } from "./NewEnvironmentModal";
import { useState } from "react";
import { UId } from "@common/uid";
import { useDispatch } from "react-redux";
import { environmentConfigActions } from "../../../../../SolutionManager/features/ConfigManager/environmentConfigState";
import { doCreateConfig } from "../services/doCreateConfig";
import { PayloadFeatureResponse } from "@appframe/index";
import { CreateConfigResponse } from "@appframe/configModule/createConfig";

export const NewEnvironmentConfig = ({solutionUId, onCreated}: {solutionUId?: UId, onCreated: () => void}) => {
    const [showDialog, setShowDialog] = useState(false);
    const dispatch = useDispatch();

    const createConfig = async ({name, description}: {name: string, description: string}) => {
        const result = new PayloadFeatureResponse<CreateConfigResponse>({})// await doCreateConfig(solutionUId, name, description);

        if(result.HasError() || !result.Payload) {
            Modal.error({
                title:'Unable to create config group',
                content: result.Error?.Message
            });

            return;
        }

        // Add the environment config to the state
        dispatch(environmentConfigActions.addEnvironmentConfig({
            uid: result.Payload.UId,
            checksum: result.Payload.Checksum,
            configKeys: {},
            modules: [],
            tasks: [],
            alerts: []
        }));
        
        onCreated();
    }

    return (
        <>
            <Button icon={<FileAddOutlined />} onClick={() => setShowDialog(true)}>New Config</Button>
            <NewEnvironmentModal show={showDialog} onOk={createConfig} />
        </>
    )
}