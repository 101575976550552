import { UId } from "@common/uid";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

export interface EnvironmentConfig {
    uid: UId;
    checksum: string;
    configKeys: { [key: string]: string },
    modules: EnvironmentModuleConfig[];
    tasks: EnvironmentScheduledTask[];
    alerts: EnvironmentAlert[];
}

interface EnvironmentModuleConfig {
    moduleReleaseUId: UId;
    configKeys: { [key: string]: EnvironmentModuleConfigValue }
}

interface EnvironmentModuleConfigValue {
    value: string;
    /** True if the value should be encrypted */
    secret?: boolean;
    /** True if the value should be explicitly set for each environment the config is applied to */
    explicit?: boolean
}

interface EnvironmentScheduledTask {
    uid: UId;
}

interface EnvironmentAlert {
    uid: UId;
}

const environmentConfigEntityAdapter = createEntityAdapter<EnvironmentConfig>({
    selectId: s => s.uid
});

const environmentConfigSlice = createSlice({
    name: 'environmentConfigs',
    initialState: environmentConfigEntityAdapter.getInitialState(),
    reducers: {
        addEnvironmentConfig: environmentConfigEntityAdapter.addOne,
        setEnvironmentConfig: environmentConfigEntityAdapter.setOne,
        updateEnvironmentConfig: environmentConfigEntityAdapter.updateOne
    }
});

export const environmentConfigReducer = environmentConfigSlice.reducer;

export const environmentConfigActions = environmentConfigSlice.actions;
export const environmentConfigSelectors = environmentConfigEntityAdapter.getSelectors();