import { Route, Routes } from "react-router-dom"
import { SolutionsPage } from "./solutions/SolutionsPage"
import { SolutionHomePage } from "./solution-home/SolutionHomePage"
import { EnvironmentConfigsPage } from "./environment-configs/EnvironmentConfigsPage"

export const SolutionManagerRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<SolutionsPage />} />
            <Route path="/solutions/:solutionUId" element={<SolutionHomePage />}>
                <Route index element={<p>home!!!</p>} />
                <Route path="home" element={<p>home!!!</p>} />
                <Route path="configs" element={<EnvironmentConfigsPage />} />
            </Route>
        </Routes>
    )
}