import { GetTenantsResponse } from '@appframe/tenants/getTenants'
import { SolutionsListItem } from './SolutionsListItem';
import { ListPlaceholder } from '../../../../../ui/skeleton/ListPlaceholder';

type SolutionsListProps = {
    solutions: GetTenantsResponse[];
    isLoading?: boolean;
}

export const SolutionsList = ({solutions, isLoading}: SolutionsListProps) => {
    return (
        <>
            {isLoading && (<ListPlaceholder />)}
            {!isLoading && (
                <div className="overflow-hidden bg-white shadow sm:rounded-md">
                    <ul role="list" className="divide-y divide-gray-200">
                    {solutions.map((solution) => (<SolutionsListItem item={solution} />))}
                    </ul>
                </div>
            )}
        </>
    )
}