import { PlusIcon } from "@heroicons/react/20/solid";
import { SolutionsList } from "./view-solutions/components/SolutionsList";
import { useSolutions } from "./view-solutions/hooks/useSolutions"
import { Pagination } from "../../../ui/pagination/Pagination";
import { SearchBox } from "../../common/input/search/SearchBox";
import { CreateSolutionSlideOver } from "./create-solution/components/CreateSolutionSlideOver";
import { useState } from "react";
import { Button } from "../../../ui/button/Button";
import { Layout } from "../../../ui/layout/Layout";
import { Header } from "../../../ui/layout/Header";
import { Main } from "../../../ui/layout/Main";

export const SolutionsPage = () => {

    const { solutions, pager, setPage, setFilter, isLoading, isFirstLoad, refresh } = useSolutions();
    const [showCreateSolution, setShowCreateSolution] = useState(false);

    const onCreated = async () => {
        setShowCreateSolution(false)
        await refresh()
    }

    return (
        <Layout>
            <CreateSolutionSlideOver 
                show={showCreateSolution} 
                onCancel={() => setShowCreateSolution(false)} 
                onCreated={onCreated} />
            <Header>
                <h1 className="flex-1">Customer Solutions</h1>
                <SearchBox onChange={setFilter} className="mr-2 sm:mb-1" />
                <Button themeType="primary" icon={<PlusIcon />} onClick={() => setShowCreateSolution(true)}>Create Solution</Button>
            </Header>

            <Main>
                <SolutionsList solutions={solutions.Results} isLoading={isLoading && isFirstLoad} />
                <Pagination page={pager.page} pageCount={solutions.PageCount} onPageChange={setPage} />
            </Main>
        </Layout>
    );
}