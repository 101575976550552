import classNames from "classnames";
import { portalStateStore } from "state/portalStateStore";

type ButtonProps = {
    children?: React.ReactNode;
    size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl';
    themeType?: 'primary' | 'secondary' | 'soft' | 'link' | 'link-primary';
    rounded?: boolean;
    icon?: React.ReactElement;
    iconLocation?: 'leading' | 'trailing';
    className?: string;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const getSize = (props: ButtonProps) => {
    if(props.themeType === 'link' || props.themeType === 'link-primary') {
        switch(props.size) {
            case 'xs':
                return ['text-xs'];
            default:
                return ['text-sm'];
        }
    }

    switch (props.size) {
        case 'xs':
            return ['px-2', 'py-1', 'text-xs'];
        case 'sm':
            return ['px-2', 'py-1', 'text-sm'];
        case 'lg':
            return ['px-3', 'py-2', 'text-sm'];
        case 'xl':
            return ['px-3.5', 'py-2.5', 'text-sm'];
        case 'base':
        default:
            return ['px-2.5', 'py-1.5', 'text-sm'];
    }
}

const getRounded = (props: ButtonProps) => {
    if (props.rounded)
        return 'rounded-full';

    if (props.size && ['xl', 'sm'].includes(props.size))
        return 'rounded';

    return 'rounded-md'
}

const getColour = (props: ButtonProps) => {
    if (props.themeType === 'primary')
        return [
            'bg-indigo-600',
            'disabled:bg-indigo-400',
            'text-white',
            'hover:bg-indigo-500',
            'focus:outline',
            'focus:outline-2',
            'focus:outline-offset-2',
            'focus:outline-indigo-600'
        ];

    if (props.themeType === 'soft')
        return [
            'bg-indigo-50',
            'text-indigo-600',
            'hover:bg-indigo-100'
        ];


    if(props.themeType === 'link')
        return [
            'bg-white',
            'text-gray-700',
            'hover:text-gray-500'
        ];

    if(props.themeType === 'link-primary') 
        return [
            'bg-white',
            'text-indigo-600',
            'hover:text-indigo-500'
        ];

    // Default (secondary)
    return [
        'bg-white',
        'text-gray-900',
        'ring-1',
        'ring-inset',
        'ring-gray-300',
        'hover:bg-gray-50'
    ]
}

const getIconStyles = (props: ButtonProps) => {
    if (!props.icon)
        return [];

    const classes = [
        'inline-flex',
        'items-center'
    ]

    switch (props.size) {
        case 'xs':
            classes.push('gap-x-0.5');
            break;
        case 'sm':
            classes.push('gap-x-1');
            break;
        case 'xl':
            classes.push('gap-x-2');
            break;
        default:
            classes.push('gap-x-1.5');
    }

    return classes;
}

const getIconSizeClass = (props: ButtonProps) => {
    switch(props.size) {
        case 'xs':
            return 'h-3 w-3';
        case 'sm':
            return 'h-4 w-4';
        default:
            return 'h-5 w-5' + (getIconLocation(props) === 'leading' ? ' -ml-0.5' : ' -mr-0.5');
    }
}

const getIconLocation = (props: ButtonProps) => {
    if (props.iconLocation && props.iconLocation === 'trailing')
        return 'trailing';

    return 'leading'
}

const getShadow = (props: ButtonProps) => {
    if(props.themeType !== 'link') {
        return 'shadow-sm';
    }

    return '';
}

export const Button = (props: ButtonProps) => {

    const classes = [
        'font-medium',
        'disabled:opacity-75',
        'disabled:cursor-not-allowed'
    ];

    classes.push(getShadow(props));
    classes.push(getRounded(props));
    classes.push(...getColour(props));
    classes.push(...getSize(props));
    classes.push(...getIconStyles(props));

    if(props.className)
        classes.push(props.className);

    /*
    if(props.disabled) {
        classes.push('pointer-events-none');
    }
    */

    const getIcon = () => <div aria-hidden="true" className={getIconSizeClass(props)}>{props.icon}</div>

    // Add our classNames to any in the props
    const p = {...props};
    p.className = classNames(classes, props.className);

    return (
        <button {...p}>
            {props.icon && getIconLocation(props) === 'leading' && getIcon()}
            {props.children}
            {props.icon && getIconLocation(props) === 'trailing' && getIcon()}
        </button>
    )
}