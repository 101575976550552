import { useAppFrame } from "@appframe/base/hooks/useAppFrame"
import { CreateConfigArgs, createConfig } from "../createConfig";
import { CreateConfigGroupArgs, createConfigGroup } from "../createConfigGroup";
import { GetConfigDataArgs, getConfigData } from "../getConfigData";
import { GetConfigGroupsArgs, getConfigGroups } from "../getConfigGroups";
import { GetConfigsInGroupArgs, getConfigsInGroup } from "../getConfigs";
import { GetPublishedConfigDataArgs, getPublishedConfigData } from "../getPublishedConfigData";
import { PublishConfigGroupArgs, publishConfigGroup } from "../publishConfigGroup";
import { UpdateConfigDataArgs, updateConfigData } from "../updateConfigData";

export const useConfigModule = () => {
    const { getUrl } = useAppFrame();

    return {
        createConfig: (args: CreateConfigArgs) => createConfig(getUrl, args),
        createConfigGroup: (args: CreateConfigGroupArgs) => createConfigGroup(getUrl, args),
        getConfigData: (args: GetConfigDataArgs) => getConfigData(getUrl, args),
        getConfigGroups: (args: GetConfigGroupsArgs) => getConfigGroups(getUrl, args),
        getConfigsInGroup: (args: GetConfigsInGroupArgs) => getConfigsInGroup(getUrl, args),
        getPublishedConfigData: (args: GetPublishedConfigDataArgs) => getPublishedConfigData(getUrl, args),
        publishConfigGroup: (args: PublishConfigGroupArgs) => publishConfigGroup(getUrl, args),
        updateConfigData: (args: UpdateConfigDataArgs) => updateConfigData(getUrl, args)
    }
}