import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UId } from "../helpers";

interface RuleEditorState {
    currentRuleUId: UId | null;
    selectedNodeId: number | null;
}

const initialState: RuleEditorState = {
    currentRuleUId: null,
    selectedNodeId: null
}

const ruleEditorSlice = createSlice({
    name: 'ruleEditor',
    initialState: initialState,
    reducers: {
        setCurrentRuleUId: (state, entity: PayloadAction<UId | null>) => {
            state.currentRuleUId = entity.payload
        },
        setSelectedNodeId: (state, entity: PayloadAction<number | null>) => {
            state.selectedNodeId = entity.payload;
        }
    }
});

export const ruleEditorReducer = ruleEditorSlice.reducer;

export const ruleEditorActions = ruleEditorSlice.actions;
export const ruleEditorSelectors = {};