import jwtDecode from "jwt-decode";
import { useState } from "react";
import { PayloadFeatureResponse } from "../../appframeClient";
import { useAuthModule } from "@appframe/auth/hooks/useAuthModule";
import { devFlags } from "../../features/dev/devFlags";

export function useAuthProvider(): AuthContext {
    const [user, setUser] = useState<AuthIdentity | undefined>(devFlags.isDev ? {
        uid: '',
        accessToken: devFlags.devToken
    } : undefined);

    const { goAvailable } = useAuthModule();

    return {
        
        signinAsync: async (username: string, pass: string): Promise<PayloadFeatureResponse<AuthIdentity>> => {
            let response = await goAvailable(username, pass);

            if(response.HasError()) {
                return new PayloadFeatureResponse<AuthIdentity>({error: response.Error})
            }

            if(!response.Payload){
                return new PayloadFeatureResponse<AuthIdentity>({
                    error: {
                        Reference: "MissingAuthPayload",
                        Message: "Missing auth payload"
                    }
                });
            }

            let token = jwtDecode<AuthToken>(response.Payload.AccessToken);

            setUser({
                uid: token.usr,
                accessToken: response.Payload.AccessToken
            })

            return new PayloadFeatureResponse<AuthIdentity>({
                payload: user
            });
        },
        user: user
    };
}

type AuthToken = {
    usr: string;
    env: string
}

export type AuthContext = {
    signinAsync?: (username: string, password: string) => Promise<PayloadFeatureResponse<AuthIdentity>>;
    user?: AuthIdentity;
}

export type AuthIdentity = {
    uid: string;
    accessToken: string;
}