import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { notification } from "antd";
import { ArgsProps } from "antd/es/notification/interface";
import { createContext, ReactNode } from "react";
import { Id, toast, ToastContainer } from "react-toastify";
import { SimpleToast, SimpleToastButton, SimpleToastType } from "./SimpleToast";

interface NotificationContextOptions {
    actions?: [{title: string, action: (notification: Notification) => void}];
    showDismiss?: boolean;
    showClose?: boolean;
}

interface NotificationContext {
    showSuccess: (title: string, description: string, options?: NotificationContextOptions) => void;
    showError: (title: string, description: string, options?: NotificationContextOptions) => void;
}

export interface Notification {
    dismiss: () => void;
}

export const NotificationContext = createContext<NotificationContext>({
    showSuccess: () => {},
    showError: () => {}
});

export const NotificationProvider = ({children}: {children: ReactNode}) => {

    const showToast = (title: string, description: string, type: SimpleToastType, options?: NotificationContextOptions) => {
        let buttons: SimpleToastButton[] | undefined;
        let id: Id;

        if(options) {
            if(options.actions) {
                const notification: Notification = {
                    dismiss: () => toast.dismiss(id)
                }

                buttons = options.actions.map<SimpleToastButton>(a => ({
                    text: a.title,
                    action: () => a.action(notification),
                    primary: true
                }));

                if(options.showClose === undefined || options.showClose === true) {
                    buttons.push({
                        text: 'Dismiss',
                        action: () => toast.dismiss(id)
                    })
                } 
            }
        }

        id = toast(<SimpleToast title={title} description={description} type={type} buttons={buttons} />, {
            draggable: false
        });
    }



    const context: NotificationContext = {
        showSuccess: (title, description, options) => showToast(title, description, 'success', options),
        showError: (message: string) => toast.error(message)
    };

    return (
        <NotificationContext.Provider value={context}>
            <ToastContainer 
                closeButton={false}
                closeOnClick={false}
                toastClassName={() => "pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"}
                bodyClassName={() => "text-sm font-white font-med block p-3"}
                progressClassName={() => "bg-indigo-600 h-0.5 rounded-full origin-left Toastify__progress-bar--animated"} />
            {children}
        </NotificationContext.Provider>
    )
}