import { AppFrameUrlBuilder } from "@appframe/base/hooks/useAppFrame";
import { UId } from "../../helpers";
import { getPayloadAsync } from "../base/clientBase";

type PublishedConfigDataResponse = {
    Data: string;
}

export type GetPublishedConfigDataArgs = {
    publishedConfigUId: UId
}

export const getPublishedConfigData = async(urlBuilder: AppFrameUrlBuilder, { publishedConfigUId }:GetPublishedConfigDataArgs) => {
    const response = await getPayloadAsync<PublishedConfigDataResponse>({
        url: urlBuilder({url: `/configuration/v2/publishedconfigs/${publishedConfigUId}`})
    });

    return response;
}