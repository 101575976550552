type HeaderProps = {
    children: React.ReactNode;
}

export const Header = ({children}: HeaderProps) => {
    return (
        <>
            <header className="bg-white shadow-sm">
                <div className="md:flex md:items-center md:justify-between mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
                    {children}
                </div>
            </header>
        </>
    )
}