import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { SlideOver } from "./SlideOver"
import { Dialog } from "@headlessui/react";
import { Button } from "../../ui/button/Button";
import { FeatureError } from "@appframe";
import { Alert } from "../../ui/alert/Alert";

type CreateItemSlideOverProps = {
    show: boolean;
    onCancel: () => void;
    onSubmit: () => void;
    title: string;
    description: string
    children: React.ReactNode;
    canSubmit?: boolean;
    isSubmitting?: boolean
    error?: FeatureError;
}

export const CreateItemSlideOver = ({ show, onCancel, onSubmit, title, description, children, canSubmit, isSubmitting, error }: CreateItemSlideOverProps) => {
    const onSliderOverCancel = () => {
        // Do nothing if we are creating
        if (isSubmitting) return;

        onCancel();
    }

    return (
        <SlideOver show={show} onClose={onSliderOverCancel}>
            <form onSubmit={onSubmit} className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div className="h-0 flex-1 overflow-y-auto">
                    <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                            <Dialog.Title className="text-base font-semibold leading-6 text-white">
                                {title}
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                                <button
                                    type="button"
                                    className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                    onClick={onSliderOverCancel}
                                >
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                        <div className="mt-1">
                            <p className="text-sm text-indigo-300">
                                {description}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                            <div className="space-y-6 pb-5 pt-6">
                                {children}
                            </div>
                        </div>
                    </div>
                    {error && (
                        <div className="px-6">
                            <Alert type="error" message={error.Message} />
                        </div>
                    )}
                </div>
                <div className="flex flex-shrink-0 justify-end px-4 py-4">
                    <Button disabled={isSubmitting} type="button" onClick={onSliderOverCancel}>Cancel</Button>
                    <Button disabled={!canSubmit || isSubmitting} themeType="primary" className="ml-4">Submit</Button>
                </div>
            </form>
        </SlideOver>
    )
}