import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/components/ProvideAuth";
import { FormInput } from "../../ui/form/FormInput";
import { useForm } from "react-hook-form";
import { Button } from "../../ui/button/Button";
import { useState } from "react";
import { FeatureError } from "@appframe/index";
import { Alert } from "../../ui/alert/Alert";
import { TMLogo } from "../../ui/branding/TMLogo";

interface LoginForm {
	username: string;
	password: string;
	remember: boolean
}

const LoginPage = () => {
	const auth = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const [loginError, setLoginError] = useState<FeatureError>()

	const { handleSubmit, register, formState, watch } = useForm<LoginForm>();

	const watchSub = watch((form) => {
		if(loginError){
			setLoginError(undefined);
		}
	});

	const from = location.state?.from?.pathname || "/";

	const onSubmit = (values: LoginForm) => {
		if (!auth.signinAsync) return;

		auth.signinAsync(values.username, values.password)
			.then(r => {
				if (r.HasError()) {
					setLoginError(r.Error);
				} else {
					watchSub.unsubscribe();
					navigate(from, { replace: true });
				}
			});
	};

	return (
		<>
			<div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<TMLogo />
					<h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
						Sign in to your account
					</h2>
				</div>

				<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
					<div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
						<form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
							<FormInput label="Username" type="text" register={register('username', {required: true})} formState={formState} />
							<FormInput label="Password" type="password" register={register('password', {required: true})} formState={formState} />

							<div className="flex items-center justify-between">
								<div className="flex items-center">
									<input
										disabled
										id="remember-me"
										name="remember-me"
										type="checkbox"
										className="opacity-50 cursor-not-allowed h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
									/>
									<label htmlFor="remember-me" className="opacity-50 cursor-not-allowed ml-3 block text-sm leading-6 text-gray-900">
										Remember me
									</label>
								</div>

								<div className="text-sm leading-6">
									<a href="#" className="opacity-50 cursor-not-allowed font-semibold text-indigo-600 hover:text-indigo-500">
										Forgot password?
									</a>
								</div>
							</div>

							<div>
								<Button disabled={!formState.isValid} type="submit" themeType="primary" className="w-full">Sign in</Button>
							</div>
							{loginError && (
								<Alert type="error" message={loginError.Message} />
								/*
								<p className="mt-2 text-sm text-red-600">
									{loginError.Message}
								</p>
								*/
							)}

						</form>

						<div>
							<div className="relative mt-10">
								<div className="absolute inset-0 flex items-center" aria-hidden="true">
									<div className="w-full border-t border-gray-200" />
								</div>
								<div className="relative flex justify-center text-sm font-medium leading-6">
									<span className="bg-white px-6 text-gray-900">Or continue with</span>
								</div>
							</div>

							<div className="mt-6 grid grid-cols-2 gap-4">
								<a
									href="#"
									className="opacity-50 cursor-not-allowed flex w-full items-center justify-center gap-3 rounded-md bg-[#24292F] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]"
								>
									<svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
										<rect x="1" y="1" width="9" height="9" fill="#f25022" /><rect x="1" y="11" width="9" height="9" fill="#00a4ef" /><rect x="11" y="1" width="9" height="9" fill="#7fba00" /><rect x="11" y="11" width="9" height="9" fill="#ffb900" />
									</svg>
									<span className="text-sm font-semibold leading-6">Microsoft</span>
								</a>

								<a
									href="#"
									className="opacity-50 cursor-not-allowed flex w-full items-center justify-center gap-3 rounded-md bg-[#1D9BF0] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]"
								>
									<svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
										<path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
									</svg>
									<span className="text-sm font-semibold leading-6">Twitter</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default LoginPage;