import { EmptyFeatureResponse, FeatureError, FeatureResponse, PagedFeatureResponse, PayloadFeatureResponse } from "./featureResponse";

export type RequestParams = {
    url: URL;
    headers?: Map<string, string>;
}

const getUrl = (args: RequestParams) => {
    return args.url;
}

const buildRequest = (method: string, args: RequestParams): RequestInit => {
    let request: RequestInit = {
        method: method
    };

    if(args.headers && args.headers.size > 0) {
        let headers: any = {};

        args.headers.forEach((val, key) => headers[key] = val)

        request.headers = headers;
    }

    return request;
}

const getAsync = async(args: RequestParams): Promise<Response> => {
    return await fetch(getUrl(args), buildRequest('GET', args));
}

const postAsync = async<TPayload>(payload: TPayload, args: RequestParams): Promise<Response> => {
    
    if(!args.headers) {
        args.headers = new Map<string, string>();
    }

    args.headers?.set('Content-Type', 'application/json');
    
    let request = buildRequest('POST', args);

    request.body = JSON.stringify(payload);
    
    return await fetch(getUrl(args), request);
}

const putAsync = async<TPayload>(payload: TPayload, args: RequestParams): Promise<Response> => {

    if(!args.headers) {
        args.headers = new Map<string, string>();
    }

    args.headers?.set('Content-Type', 'application/json');

    let request = buildRequest('PUT', args);

    request.body = JSON.stringify(payload);
    
    return await fetch(getUrl(args), request);
}

export const getPayloadAsync = async<TPayload>(args: RequestParams): Promise<PayloadFeatureResponse<TPayload>> => {
    const response = await getAsync(args);

    return await toPayloadResponseAsync<TPayload>(response);
}

export const postPayloadAsync = async<TRequestPayload, TResponsePayload>(payload: TRequestPayload, args: RequestParams) : Promise<PayloadFeatureResponse<TResponsePayload>> => {
    const response = await postAsync<TRequestPayload>(payload, args);

    return await toPayloadResponseAsync<TResponsePayload>(response);
}

export const getPagedAsync = async<TPayload>(args: RequestParams): Promise<PagedFeatureResponse<TPayload>> => {
    const response = await getAsync(args);

    return await toPagedResponseAsync<TPayload>(response);
}

export const postEmptyAsync = async<TRequestPayload>(payload: TRequestPayload, args: RequestParams): Promise<EmptyFeatureResponse> => {
    const response = await postAsync<TRequestPayload>(payload, args);

    return toEmptyResponseAsync(response);
}

export const putEmptyAsync = async<TRequestPayload>(payload: TRequestPayload, args: RequestParams): Promise<EmptyFeatureResponse> => {
    const response = await putAsync<TRequestPayload>(payload, args);

    return toEmptyResponseAsync(response);
}

export const putPayloadAsync = async<TRequestPayload, TResponsePayload>(payload: TRequestPayload, args: RequestParams) : Promise<PayloadFeatureResponse<TResponsePayload>> => {
    const response = await putAsync<TRequestPayload>(payload, args);

    return await toPayloadResponseAsync<TResponsePayload>(response);
}

const toEmptyResponseAsync = async(response: Response): Promise<EmptyFeatureResponse> => {    
    if(response.ok === true) {
        return new EmptyFeatureResponse();
    } else {
        const data = await response.json();
        return getErrorFeatureResponseFromResponse(data) as EmptyFeatureResponse;        
    }
}

const toPayloadResponseAsync = async <TPayload>(response: Response): Promise<PayloadFeatureResponse<TPayload>> => {
    const data = await response.json();

    if(response.ok === true) {
        const payloadResonse = new PayloadFeatureResponse<TPayload>({payload: data});

        return payloadResonse;
    }
        
    return getErrorFeatureResponseFromResponse(data) as PayloadFeatureResponse<TPayload>;        
}

const toPagedResponseAsync = async <TPayload>(response: Response) => {
    const data = await response.json();

    if(response.ok === true) {
        const pagedResponse = new PagedFeatureResponse<TPayload>({results: data.Results, pageCount: data.PageCount});
        
        return pagedResponse;
    }

    return getErrorPagedFeatureResponseFromResponse<TPayload>(data);
}

const getErrorPagedFeatureResponseFromResponse = <TPayload>(data: any) => {
    const featurError = data as FeatureError;

    return new PagedFeatureResponse<TPayload>({error: featurError});
}

const getErrorFeatureResponseFromResponse = (data: any): FeatureResponse => {
    let featureError = data as FeatureError;

    return new FeatureResponse(featureError);
}