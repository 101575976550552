import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { UId } from "../../../helpers"
import { PortalState } from "../../../state/portalStateStore"

export const enum CallTypeType {
    NONE = 'none',

    // Core
    BASIC = 'basic',

    // TASK
    STANDARD = 'task_standard',
    FRONTSCREEN = 'task_frontscreen',
    UG = 'task_ug'
}

export type CallType = {
    uid: string;
    name: string;
    type: CallTypeType;
    screenUIds: UId[];
    variableUIds: UId[];
    serverRuleUIds: UId[];
    clientRuleUIds: UId[];
}

const callTypesEntityAdapter = createEntityAdapter<CallType>({
    selectId: s => s.uid
});

const callTypesSlice = createSlice({
    name: 'callTypes',
    initialState: callTypesEntityAdapter.getInitialState(),
    reducers: {
        addCallType: callTypesEntityAdapter.addOne,
        addCallScreen: (state, action: PayloadAction<{callTypeUId: UId, callScreenUId: UId}>) => {
            const callType = state.entities[action.payload.callTypeUId];

            if(!callType) {
                console.error("callTypesActions.addCallScreen - Unable to find call type with uid", action.payload.callTypeUId);
                return;
            }

            callType.screenUIds.push(action.payload.callScreenUId);
        },
        addVariable: (state, action: PayloadAction<{callTypeUId: UId, variableUId: UId}>) => {
            const callType = state.entities[action.payload.callTypeUId];

            if(!callType) {
                console.error("callTypesActions.addVariable - Unable to find call type with uid", action.payload.callTypeUId);
                return;
            }

            callType.variableUIds.push(action.payload.variableUId);
        }
    }
});

// Selectors
const selectByIds = createSelector(
    (state: PortalState, uids: UId[]) => ({state, uids}),
    ({state, uids}) => {
        return uids.map(uid => state.solutionManager.callTypes.entities[uid]) as CallType[];
    }
)

export const callTypesReducer = callTypesSlice.reducer;

export const callTypesActions = callTypesSlice.actions;
export const callTypesSelectors = {
    ...callTypesEntityAdapter.getSelectors(),
    selectByIds
};