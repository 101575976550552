import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

type SearchBoxProps = {
    onChange: (value: string) => void;
    className?: string;
}

export const SearchBox = ({ onChange, className }: SearchBoxProps) => {
    let cn = "w-full max-w-lg lg:max-w-xs";

    if(className)
        cn = cn + ' ' + className;

    return (
        <div className={cn}>
            <label htmlFor="search" className="sr-only">
                Search
            </label>
            <div className="relative text-gray-400 focus-within:text-gray-600">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                    id="search"
                    className="block w-full shaddow-sm ring-1 ring-inset ring-gray-300 rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Search"
                    type="search"
                    name="search"
                    onChange={(e) => onChange(e.target.value)}
                />
            </div>
        </div>
    )
}