import { useForm } from "react-hook-form";
import { CreateItemSlideOver } from "../../../../../ui/slide-over/CreateItemSlideOver";
import { FormInput } from "../../../../../ui/form/FormInput";
import { FormTextArea } from "../../../../../ui/form/FormTextArea";
import { UId } from "@common/uid";
import { useCreateSolution } from "../hooks/useCreateSolution";
import { useNotification } from "../../../../../ui/notification";
import { FeatureError } from "@appframe";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Notification } from "../../../../../ui/notification/components/NotificationContext";

type CreateSolutionSildeOverProps = {
    show: boolean;
    onCancel: () => void;
    onCreated: () => void;
}

interface CreateSolutionForm {
    name: string;
    description: string;
}

export const CreateSolutionSlideOver = ({ show, onCancel, onCreated }: CreateSolutionSildeOverProps) => {
    const {register, handleSubmit, formState, reset} = useForm<CreateSolutionForm>();
    const { createSolution, isCreating } = useCreateSolution();
    const { showSuccess } = useNotification();
    const [ error, setError ] = useState<FeatureError>();
    const navigate = useNavigate();

    const onSlidOverCancel = () => {
        reset();
        onCancel();
    }

    const onSlideOverSubmit = async (form: CreateSolutionForm) => {
        const response = await createSolution(form.name, form.description);

        if(response.Error) {
            setError(response.Error);
            return;
        }

        reset();
        onCreated();

        showSuccess('Solution Created', `Solution ${form.name} has been successfully created.`, { 
            actions: [{title: 'View', action: (notification: Notification) => {
                navigate(`solutions/${response.Payload?.UId}`);
                notification.dismiss();
            }}]
        });
    }

    return (
        <CreateItemSlideOver 
            show={show}
            onSubmit={handleSubmit(onSlideOverSubmit)} 
            onCancel={onSlidOverCancel} 
            canSubmit={formState.isValid}
            title="Create Solution" 
            description="Create a new customer solution"
            isSubmitting={isCreating}
            error={error}>
            <FormInput register={register('name', {required: 'Solution Name is required'})} formState={formState} type="text" label="Solution Name" />
            <FormTextArea register={register('description', {required: 'Description is required'})} formState={formState} label="Description" />
        </CreateItemSlideOver>
    )
}