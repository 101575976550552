import { AppFrameUrlBuilder } from "@appframe/base/hooks/useAppFrame";
import { UId } from "../../helpers";
import { getPayloadAsync } from "../base/clientBase";

type GetConfigDataResponse = {
    Data: string;
    Checksum: string;
}

export type GetConfigDataArgs = {
    configUId: UId
}

export const getConfigData = async(urlBuilder: AppFrameUrlBuilder, {configUId}: GetConfigDataArgs) => {
    const response = await getPayloadAsync<GetConfigDataResponse>({
        url: urlBuilder({url: `/configuration/v2/configs/${configUId}/data`})
    });

    return response;
}