import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UId } from "../../helpers";
import { defaultDevice } from "../features/DesignerCanvas/devices";

interface SelectedItem {
    uid: UId;
    type: 'control' | 'screen';
}
export type EditorDeviceGroup = 'ios' | 'android';

export type DeviceOrientation = 'landscape' | 'portrait';

export interface EditorDevice {
    id: string;
    name: string;
    group: EditorDeviceGroup;
    resolution: number[];
}

export interface DraggingControlState {
    uid: UId;
    parentUId?: UId;
}

interface ScreenEditorState {
    selectedItem: SelectedItem | null;
    selectedDevice: EditorDevice;
    deviceOrientation: DeviceOrientation;
    draggingControl: DraggingControlState | null;
}


const initialState: ScreenEditorState = {
    selectedItem: null,
    selectedDevice: defaultDevice,
    deviceOrientation: 'portrait',
    draggingControl: null
};

const editorSlice = createSlice({
    name: 'callScreenEditor',
    initialState: initialState,
    reducers:{
        setSelectedItem: (state, entity: PayloadAction<SelectedItem | null>) => {
            state.selectedItem = entity.payload;
        },
        setSelectedDevice: (state, entity: PayloadAction<EditorDevice>) => {
            state.selectedDevice = entity.payload;
        },
        setDeviceOrientation: (state, entity: PayloadAction<DeviceOrientation>) => {
            state.deviceOrientation = entity.payload;
        },
        setDraggingControl: (state, entity: PayloadAction<DraggingControlState |  null>) => {
            state.draggingControl = entity.payload;
        }
    }
})


export const callScreenEditorReducer = editorSlice.reducer;

export const callScreenEditorActions = editorSlice.actions;
export const callScreenEditorSelectors = {
    
}