import { useTenantsModule } from "@appframe/tenants/hooks/useTenantsModule";
import { useState } from "react";

export const useCreateSolution = () => {
    const [isCreating, setIsCreating] = useState(false);
    const { createTenant } = useTenantsModule();

    const createSolution = async (name: string, description: string) => {
        setIsCreating(true);
        
        // Reference is name without spaces
        const reference = name.replaceAll(' ', '')

        const response = await createTenant({ name, reference, description });
        
        setIsCreating(false);

        return response;
    }

    return {
        createSolution,
        isCreating
    }
}