import { AppFrameUrlBuilder } from "@appframe/base/hooks/useAppFrame";
import { postPayloadAsync } from "../base/clientBase";

type CreateConfigGroupRequest = {
    Name: string;
    Description: string;
    Labels: string[];
}

export type CreateConfigGroupArgs = {
    name: string, 
    description: string, 
    labels: string[]
}

export const createConfigGroup = async (urlBuilder: AppFrameUrlBuilder, { name, description, labels }: CreateConfigGroupArgs) => {
    const response = await postPayloadAsync<CreateConfigGroupRequest, string>({
        Name: name,
        Description: description,
        Labels: labels
    }, {
        url: urlBuilder({url: '/configuration/v2/groups'})
    });

    return response;
}