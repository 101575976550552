import { AppFrameUrlBuilder } from "@appframe/base/hooks/useAppFrame";
import { UId } from "../../helpers";
import { postPayloadAsync } from "../base/clientBase";

type PublishConfigGroupRequest = {
    Draft: Boolean;
}

type PublishConfigGroupResponse = {
    PublishUId: UId;
}

export type PublishConfigGroupArgs = {
    groupUId: UId, 
    draft: boolean
}

export const publishConfigGroup = async (urlBuilder: AppFrameUrlBuilder, { groupUId, draft }: PublishConfigGroupArgs) => {

    const response = await postPayloadAsync<PublishConfigGroupRequest, PublishConfigGroupResponse>({
        Draft: draft
    }, {
        url: urlBuilder({url: `/configuration/v2/groups/${groupUId}/publish`})
    });

    return response;
}