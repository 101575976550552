import { ReactNode, createContext, useContext } from "react";
import { AuthContext, useAuthProvider } from "../hooks/useAuthProvider";

const authContext = createContext<AuthContext>({});

export function ProvideAuth({children}: {children: ReactNode}) { 
    let auth = useAuthProvider();
    
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => useContext(authContext);