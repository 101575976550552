import { ReactNode } from 'react';
import './App.css';
import { ProvideAuth, useAuth } from './auth/components/ProvideAuth';
import { Navigate, Route, Routes, useLocation, BrowserRouter } from 'react-router-dom';
import LoginPage from './features/auth/loginPage';
import { Provider } from 'react-redux';
import { portalStateStore } from './state/portalStateStore';
import 'react-toastify/dist/ReactToastify.css';
import { getVersion } from './features/version/version';
import { SolutionManagerRoutes } from './features/solution-manager/SolutionManagerRoutes';
import { NotificationProvider } from './ui/notification';


const App = () => {
    return (
        <>
        <div className="fixed px-2 py-2 bottom-0 right-0 text-xs">
            <div className=" p-2 rounded-sm bg-white shadow-lg ring-1 ring-black ring-opacity-5">v{getVersion()}</div>
        </div>
            
            <NotificationProvider>
                <Provider store={portalStateStore}>
                    <ProvideAuth>
                        <BrowserRouter>
                            <RequireAuth>
                                <SolutionManagerRoutes />
                            </RequireAuth>

                            <Routes>
                                <Route path="/login" element={<LoginPage />} />
                            </Routes>
                        </BrowserRouter>
                    </ProvideAuth>
                </Provider>
            </NotificationProvider>
        </>
    );
}

const RequireAuth = ({ children }: { children: ReactNode }) => {
    const auth = useAuth();
    const location = useLocation();

    console.log(auth.user);


    if (location.pathname == "/login") return <></>

    if (!auth.user) {
        console.log("location: ", location);
        return <Navigate to="/login" state={{ from: location }} replace />
    }

    return (<>{children}</>);
}

export default App;
