import { AppFrameUrlBuilder } from "@appframe/base/hooks/useAppFrame";
import { UId } from "../../helpers";
import { getPayloadAsync } from "../base/clientBase";

type GetConfigsInGroupsResponse = {
    UId: UId;
    Name: string;
    Data?: string;
    Checksum?: string;
}

export type GetConfigsInGroupArgs = {
    groupUId: UId, 
    labels?: string[], 
    includeData?: boolean
}

export const getConfigsInGroup = async(urlBuilder: AppFrameUrlBuilder,  {groupUId, labels, includeData = false}: GetConfigsInGroupArgs) => {
    const url = urlBuilder({url: `/configuration/v2/groups/${groupUId}/configs`});

    if(labels) {
        url.searchParams.set('labels', labels.join(','));
    }

    if(includeData) {
        url.searchParams.set('includeData', 'true');
    }

    const response = getPayloadAsync<GetConfigsInGroupsResponse[]>({ url: url });

    return response;
}