import { AppFrameUrlBuilder } from "@appframe/base/hooks/useAppFrame";
import { UId } from "../../helpers";
import { getPagedAsync } from "../base/clientBase";
import { PageInfo } from "../base/pageInfo";

export type GetConfigGroupsResponse = {
    UId: UId;
    Name: string;
    Description: string;
    PublishedVersion?: string;
    PublishedDraftVersion?: string;
}

export type GetConfigGroupsArgs = {
    pageInfo: PageInfo, 
    labels: string[]
}

export const getConfigGroups = async (urlBuilder: AppFrameUrlBuilder, { pageInfo, labels }: GetConfigGroupsArgs) => {
    const url = urlBuilder({url: `/configuration/v2/groups/`, pageInfo});
    url.searchParams.set("labels", labels.join(','));

    const response = await getPagedAsync<GetConfigGroupsResponse>({
        url
    });

    return response;
}