export class FeatureResponse {
    public static readonly Success = new FeatureResponse();

    public Error?: FeatureError;

    constructor(error?: FeatureError) {
        this.Error = error;
    }

    public HasError(): boolean {
        if(this.Error){
            return true;
        }

        return false;
    }
}

export class PayloadFeatureResponse<TPayload> extends FeatureResponse {
    public Payload?: TPayload;

    constructor({payload, error}: {payload?: TPayload, error?: FeatureError} = {}) {
        super(error);

        this.Payload = payload;
    }
}

export class PagedFeatureResponse<TPayload> extends FeatureResponse {
    public static GetEmpty = <TPayload>() => new PagedFeatureResponse<TPayload>({results: [], pageCount: 1});

    public PageCount: number;
    public Results: TPayload[];

    constructor({results = [], pageCount = 1, error}: {results?: TPayload[], pageCount?: number, error?: FeatureError}) {
        super(error);

        this.PageCount = pageCount;
        this.Results = results;
    }
}

export class EmptyFeatureResponse extends FeatureResponse {
}

export interface FeatureError {
    Reference: string;
    Message: string;
    ChildErrors?: FeatureError[];
}