import { PagedFeatureResponse, PageInfo } from "@appframe";
import { GetConfigGroupsResponse } from "@appframe/configModule/getConfigGroups";
import { useConfigModule } from "@appframe/configModule/hooks/useConfigModule";
import { UId } from "@common/uid";
import { useEffect, useState } from "react";

export const useEnvironmentConfigs = (solutionUId?: UId, page = 1, pageSize = 10) => {
    const [pager, setPager] = useState<PageInfo>({ page: page, pageSize: pageSize });
    const [configs, setConfigs] = useState<PagedFeatureResponse<GetConfigGroupsResponse>>(PagedFeatureResponse.GetEmpty());
    const [isLoading, setIsLoading] = useState(false);

    const { getConfigGroups } = useConfigModule();

    const getConfigs = async () => {
        const configGroups = await getConfigGroups({ pageInfo: pager, labels: [`solutionuid_${solutionUId}`] });

        if(configGroups.HasError()) {
            console.error('Unable to get config groups', configGroups);
        }

        setConfigs(configGroups);
    }

    useEffect(() => {
        setIsLoading(true);
        getConfigs().then(() => setIsLoading(false));
    }, [pager]);

    const setPage = (page: number) => setPager(p => ({...p, page: page}));
    const refresh = () => getConfigs();

    return { configs, pager, setPage, refresh, isLoading };
}