import { PageInfo, PagedFeatureResponse } from "@appframe";
import { GetTenantsResponse } from "@appframe/tenants/getTenants";
import { useTenantsModule } from "@appframe/tenants/hooks/useTenantsModule";
import { useEffect, useState } from "react";

export const useSolutions = (page = 1, pageSize = 10) => {
    const [pager, setPager] = useState<PageInfo>({ page: page, pageSize: pageSize });
    const [solutions, setSolutions] = useState<PagedFeatureResponse<GetTenantsResponse>>(PagedFeatureResponse.GetEmpty());
    const [isLoading, setIsLoading] = useState(false);
    const [filterValue, setFilterValue] = useState('');
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const { getTenants } = useTenantsModule();

    const updateTenants = async () => {
        const tenants = await getTenants({ pageInfo: pager, nameFilter: filterValue });

        if(tenants.HasError()) {
            console.error('Unable to get config groups', tenants);
        }

        setSolutions(tenants);
    }

    useEffect(() => {
        setIsLoading(true);
        updateTenants().then(() => {
            setIsLoading(false);
            if(isFirstLoad) setIsFirstLoad(false);
        });
    }, [pager, filterValue]);

    const setPage = (page: number) => setPager(p => ({...p, page: page}));
    const setFilter = (value: string) => {
        setPage(1);
        setFilterValue(value);
    }
    const refresh = () => updateTenants();

    return { solutions, pager, setPage, refresh, isLoading, isFirstLoad, setFilter };
}