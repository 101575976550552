import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { FieldValues, FormState, UseFormRegisterReturn } from "react-hook-form";

type FormTextAreaProps = {
    register: UseFormRegisterReturn;
    formState: FormState<FieldValues>;
    label: string;
}

export const FormTextArea = ({register, formState, label}: FormTextAreaProps) => {
    let inputClassName ="block outline-none w-full rounded-md border-0 py-1.5 px-3 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6";

    const hasError = register.name in formState.errors;

    if (hasError) {
        inputClassName += ' pr-10 text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
    } else {
        inputClassName += ' text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600'
    }

    return (
        <div>
            <label
                htmlFor={register.name}
                className="block text-sm font-medium leading-6 text-gray-900"
            >
                {label}
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
                <textarea
                    {...register}
                    rows={4}
                    className={inputClassName}
                />
                {hasError && (
                    <div className="pointer-events-none absolute inset-y-2 right-0 flex items-top pr-3">
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div>
                )}
            </div>
        </div>
    )
}