import { UId } from "@common/uid"
import { Col, Row, Skeleton, Space } from "antd";
import { EnvironmentConfigsTable } from "./view-configs/components/EnvironmentConfigsTable";
import { PublishEnvironmentConfig } from "./publish-config/components/PublishEnvironmentConfig";
import { useEnvironmentConfigs } from "./view-configs/hooks/useEnvironmentConfigs"
import { NewEnvironmentConfig } from "./create-config/components/NewEnvironmentConfig";
import { GetConfigGroupsResponse } from "@appframe/configModule/getConfigGroups";
import { useParams } from "react-router-dom";

export const EnvironmentConfigsPage = () => {
    const {solutionUId} = useParams();
    const { configs, pager, setPage, refresh, isLoading } = useEnvironmentConfigs(solutionUId);
    
    const actions = (config: GetConfigGroupsResponse) => {
        return (
            <Space>
                <PublishEnvironmentConfig config={config} onPublished={() => refresh()} />
                <PublishEnvironmentConfig config={config} isDraft onPublished={() => refresh()} />
            </Space>
        );
    }

    return (
        <>
            <Row>
                <Col span={24} style={{marginBottom: '8px'}}>
                    <NewEnvironmentConfig solutionUId={solutionUId} onCreated={() => refresh()} />
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    {isLoading && (<Skeleton active />)}

                    {!isLoading && (<EnvironmentConfigsTable 
                            solutionUId={solutionUId} 
                            configs={configs} 
                            pager={pager}
                            onPageChange={setPage}
                            actions={actions} />)}
                </Col>
            </Row>
        </>
    )
}