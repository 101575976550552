import { GetTenantsResponse } from '@appframe/tenants/getTenants'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom';

type SolutionsListItemProps = {
    item: GetTenantsResponse;
}

export const SolutionsListItem = ({ item }: SolutionsListItemProps) => {
    const href = `/solutions/${item.UId}`
    
    return (
        <li key={item.UId}>
            <Link to={href} className="block hover:bg-gray-50">
                <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                        <div className="truncate">
                            <div className="flex text-sm">
                                <p className="truncate font-medium text-indigo-600">{item.Name}</p>
                            </div>
                            <div className="mt-2 flex">
                                <div className="flex items-center text-sm text-gray-500">
                                    <p>{item.Description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ml-5 flex-shrink-0">
                        <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                </div>
            </Link>
        </li>
    )
}