import { AppFrameUrlBuilder } from "@appframe/base/hooks/useAppFrame";
import { postPayloadAsync } from "../base/clientBase"

type CreateTenantResponse = {
    UId: string;
}

type CreateTenantRequest = {
    Name: string;
    Reference: string;
    Description: string;
}

export type CreateTenantArgs = {
    name: string, 
    reference: string, 
    description: string
}

export const createTenant = async (urlBuilder: AppFrameUrlBuilder, {name, reference, description}: CreateTenantArgs) => {
    const response = await postPayloadAsync<CreateTenantRequest, CreateTenantResponse>({
        Name: name,
        Reference: reference,
        Description: description
    }, {
        url: urlBuilder({ url: '/coretenants/v0' })
    });

    return response;
}