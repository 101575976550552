import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Input, Node, NodeEditor, Output } from "rete";
import { UId } from "../../../helpers";
import { PortalState } from "../../../state/portalStateStore";

export type RuleLocation = 'client' | 'server';

export interface RuleFlow<TCustomNodeData extends object = object> {
    uid: UId;
    name: string;
    description: string;
    location: RuleLocation;
    ruleData?: ReteRuleData;
    customNodeData: { [uid: UId]: TCustomNodeData };
    variableUIds?: UId[];
}

export interface ReteRuleData {
    id: string;
    nodes: { [index: number]: RuleFlowNode }
    
}

export interface RuleFlowNode extends Omit<Node, 'inputs' | 'outputs' | 'meta' | 'data'> {
    /*id: number;
    name: string;
    position: [number, number];
    */
    inputs: { [key: string]: Input };
    outputs: { [key: string]: Output };
    data: RuleFlowNodeData;
}

export interface RuleFlowNodeData {
    uid: UId;
    ref: string;
}

const rulesEntity = createEntityAdapter<RuleFlow>({
    selectId: r => r.uid
});

const rulesSlice = createSlice({
    name: 'rules',
    initialState: rulesEntity.getInitialState(),
    reducers: {
        addRule: rulesEntity.addOne,
        updateRule: rulesEntity.updateOne,
        updateRuleData: (state, entity: PayloadAction<{uid: UId, ruleData: ReteRuleData}>) => {
            const rule = state.entities[entity.payload.uid];

            if(!rule) {
                console.error(`rulesActions.updateRuleData - unable to find rule with uid ${entity.payload.uid}`);
                return;
            }

            rule.ruleData = entity.payload.ruleData;
        },
        updateRuleNodeData: (state, entity: PayloadAction<{ruleUId: UId, nodeUId: UId, data: object}>) => {
            const rule = state.entities[entity.payload.ruleUId];

            if(!rule) {
                console.error(`rulesActions.updateRuleNodeData - unable to find rule with uid ${entity.payload.ruleUId}`);
                return;
            }

            rule.customNodeData[entity.payload.nodeUId] = entity.payload.data;
        },
        addVariable: (state, entity: PayloadAction<{ruleUId: UId, variableUId: UId}>) => {
            const rule = state.entities[entity.payload.ruleUId];

            if(!rule) {
                console.error(`rulesActions.addVariable - unable to find rule with uid ${entity.payload.ruleUId}`);
                return;
            }

            if(!rule.variableUIds) rule.variableUIds = [];

            rule.variableUIds.push(entity.payload.variableUId);
        }
    }
});

export const rulesReducer = rulesSlice.reducer;

export const rulesActions = rulesSlice.actions;
export const rulesSelectors = {
    ...rulesEntity.getSelectors(),
    selectByIds: createSelector(
        (state: PortalState, uids: UId[]) => ({state, uids}),
        ({state, uids}) => uids.map(uid => state.solutionManager.rules.entities[uid]) as RuleFlow[]
    )
};