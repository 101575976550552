import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid'

type PaginationProps = {
    page: number;
    pageCount: number;
    onPageChange: (page: number) => void;
}

export const Pagination = ({ page, pageCount, onPageChange }: PaginationProps) => {

    const numButtons = 8;
    const minPageToSplit = 4;
    const blockSize = 4;
    const pageBuffer = 2;

    const getAllPages = (from: number, to: number) => Array.from({ length: (to - from) + 1 }, (_, idx) => from + idx);

    const getFirstPages = () => {
        if (pageCount > numButtons) {
            if (page > minPageToSplit) {
                return [
                    getPageButton(1),
                    getDots()
                ]
            } else {
                return getAllPages(1, blockSize + pageBuffer).map(p => getPageButton(p));
            }
        } else {
            // Less pages than buttons so just return all the pages
            const pages = getAllPages(1, pageCount);

            return pages.map(p => getPageButton(p));
        }
    }

    const getMiddlePages = () => {
        if (pageCount > minPageToSplit) {
            if (page > minPageToSplit && page < (pageCount - minPageToSplit)) {
                return getAllPages(page - pageBuffer, page + pageBuffer).map(p => getPageButton(p))
            }
        } else {
            // do nothing
            return (<></>);
        }
    }

    const getLastPages = () => {
        if (pageCount > numButtons) {
            if (page < (pageCount - minPageToSplit)) {
                return [
                    getDots(),
                    getPageButton(pageCount)
                ]
            } else {
                return getAllPages(pageCount - pageBuffer - minPageToSplit, pageCount).map(p => getPageButton(p))
            }
        } else {
            return (<></>)
        }
    }

    const getDots = () => {
        return (
            <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">
                ...
            </span>
        )
    }

    const getPageButton = (pageNumber: number) => {
        if (pageNumber === page) {
            // Current page
            return (
                <a
                    className="inline-flex items-center border-t-2 border-indigo-500 px-4 pt-4 text-sm font-medium text-indigo-600"
                    aria-current="page"
                >
                    {pageNumber}
                </a>
            )
        }

        // Other page
        return (
            <a
                onClick={() => onPageChange(pageNumber)}
                className="cursor-pointer inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            >
                {pageNumber}
            </a>
        )
    }


    return (
        <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
            <div className="-mt-px flex w-0 flex-1">
                {page > 1 && (
                    <a
                        onClick={() => onPageChange(page - 1)}
                        className="cursor-pointer inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    >
                        <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                        Previous
                    </a>
                )}
            </div>

            <div className="hidden md:-mt-px md:flex">
                {getFirstPages()}
                {getMiddlePages()}
                {getLastPages()}
            </div>
            <div className="-mt-px flex w-0 flex-1 justify-end">
                {page < pageCount && (

                    <a
                        onClick={() => onPageChange(page + 1)}
                        className="cursor-pointer inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    >
                        Next
                        <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </a>

                )}
            </div>
        </nav>
    )
}