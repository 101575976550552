import { AppFrameUrlBuilder } from "@appframe/base/hooks/useAppFrame";
import { getPagedAsync } from "../base/clientBase";
import { PageInfo } from "../base/pageInfo";

export type GetTenantsResponse = {
    UId: string;
    ParentTenantUId: string;
    Name: string;
    Reference: string;
    Description: string;
}

export type GetTenantsArgs = {
    pageInfo: PageInfo, 
    nameFilter?: string
}

export const getTenants = async(urlBuilder: AppFrameUrlBuilder, { pageInfo, nameFilter }: GetTenantsArgs) => {
    const url = urlBuilder({url: '/coretenants/v0', pageInfo});

    if(nameFilter && nameFilter !== '') {
        url.searchParams.set('nameFilter', nameFilter);
    }
    
    const response = await getPagedAsync<GetTenantsResponse>({
        url: url
    });

    return response;
}