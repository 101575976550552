import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UId } from "../../../helpers";
import { PortalState } from "../../../state/portalStateStore";

export interface CallScreen {
    uid: string;
    name: string;
    type: CallScreenType;
    layoutUId: UId;
    controlUIds: UId[];
    variableUIds: UId[];
}

export enum CallScreenType {
    NONE = 'none',

    // Core
    EMPTY = 'emptyscreen',

    // Task
    TASKSCREEN = 'taskscreen',
    QUESTIONSET = 'questionset',
    CYCLICBROWSER = 'cyclicbrowser',
    CALLSELECTOR = 'callselector'
}

const callScreensEntityAdapter = createEntityAdapter<CallScreen>({
    selectId: s => s.uid
});

const callScreensSlice = createSlice({
    name: 'callScreens',
    initialState: callScreensEntityAdapter.getInitialState(),
    reducers: {
        addCallScreen: callScreensEntityAdapter.addOne,
        addControl: (state, entity: PayloadAction<{screenUId: UId, controlUId: UId}>) => {
            const screen = state.entities[entity.payload.screenUId];

            if(!screen) {
                console.error(`callScreensActions.addControl - unable to find screen with uid ${entity.payload.screenUId}`);
                return;
            }

            if(!screen.controlUIds) screen.controlUIds = [];

            screen.controlUIds.push(entity.payload.controlUId);
        },
        addVariable: (state, entity: PayloadAction<{screenUId: UId, variableUId: UId}>) => {
            const screen = state.entities[entity.payload.screenUId];

            if(!screen) {
                console.error(`callScreensActions.addVariable - unable to find screen with uid ${entity.payload.screenUId}`);
                return;
            }

            if(!screen.variableUIds) screen.variableUIds = [];

            screen.variableUIds.push(entity.payload.variableUId);
        }
    }
});

// Selectors
const selectByIds = createSelector(
    (state: PortalState, uids: UId[]) => ({state, uids}),
    ({state, uids}) => uids.map(uid => state.solutionManager.callScreens.entities[uid]) as CallScreen[]
);

export const callScreensReducer = callScreensSlice.reducer;

export const callScreensActions = callScreensSlice.actions;
export const callScreensSelectors = {
    ...callScreensEntityAdapter.getSelectors(),
    selectByIds
};