type LayoutProps = {
    children: React.ReactNode;
}

export const Layout = ({children}: LayoutProps) => {
    return (
        <div className="min-h-full bg-gray-100">
            {children}
        </div>
    )
}