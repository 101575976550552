import { AppFrameUrlBuilder } from "@appframe/base/hooks/useAppFrame";
import { getPayloadAsync } from "../base/clientBase";
import { PayloadFeatureResponse } from "../base/featureResponse";
import { Buffer } from "buffer";

export type GoAvailableResponse = {
    AccessToken: string;
}

export const goAvailable = async (urlBuilder: AppFrameUrlBuilder, username: string, password: string): Promise<PayloadFeatureResponse<GoAvailableResponse>> => {
    const authHeader = `Basic ${Buffer.from(username + ':' + password).toString('base64')}`;

    const response = await getPayloadAsync<GoAvailableResponse>({
        url: urlBuilder({url: '/api/v1/auth/goavailable', removeToken: true}),
        headers: new Map<string,string>([["Authorization", authHeader]])
    });

    return response;
}