import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { callScreenEditorReducer } from "../CallScreenEditor/state/callScreenEditorState";
import { ruleEditorReducer } from "../RuleEditor/ruleEditorState";
import { CallScreenType } from "../SolutionManager/features/CallScreens/callScreensState";
import { CallTypeType } from "../SolutionManager/features/CallTypes/callTypeState";
import solutionManagerState from "../SolutionManager/state/solutionManagerState";

export const portalStateStore = configureStore({
    reducer: {
        solutionManager: solutionManagerState,
        callScreenEditor: callScreenEditorReducer,
        ruleEditor: ruleEditorReducer
    },
    preloadedState: {
        solutionManager: {
            solutions: {
                ids: ['sln1'],
                entities: {
                    sln1: {
                        uid: 'sln1',
                        name: 'Test Customer 1',
                        reference: 'TestCustomer1',
                        description: "A testing customer",
                        callTypeUIds: ['ct1', 'ct2']
                    }
                }
            },
            callTypes: {
                ids: ['ct1', 'ct2'],
                entities: {
                    ct1: {
                        uid: 'ct1',
                        name: 'Call Type 1',
                        type: CallTypeType.BASIC,
                        screenUIds: ['scr1', 'scr2'],
                        variableUIds: [],
                        serverRuleUIds: [],
                        clientRuleUIds: []
                    },
                    ct2: {
                        uid: 'ct2',
                        name: 'Call Type 2',
                        type: CallTypeType.BASIC,
                        screenUIds: ['scr3', 'scr4'],
                        variableUIds: [],
                        serverRuleUIds: [],
                        clientRuleUIds: []
                    }
                }
            },
            callScreens: {
                ids: ['scr1', 'scr2', 'scr3', 'scr4'],
                entities: {
                    scr1: {
                        uid: 'scr1',
                        name: 'Screen 1',
                        type: CallScreenType.EMPTY,
                        layoutUId: 'scr1_layout',
                        controlUIds: ['scr1_layout', 'scr1_btn1'],
                        variableUIds: []
                    },
                    scr2: {
                        uid: 'scr2',
                        name: 'Screen 2',
                        type: CallScreenType.EMPTY,
                        layoutUId: 'scr2_layout',
                        controlUIds: ['scr2_layout', 'scr2_btn1'],
                        variableUIds: []
                    },
                    scr3: {
                        uid: 'scr3',
                        name: 'Screen 3',
                        type: CallScreenType.EMPTY,
                        layoutUId: 'scr3_layout',
                        controlUIds: ['scr3_layout', 'scr3_btn1'],
                        variableUIds: []
                    },
                    scr4: {
                        uid: 'scr4',
                        name: 'Screen 4',
                        type: CallScreenType.EMPTY,
                        layoutUId: 'scr4_layout',
                        controlUIds: ['scr4_layout', 'scr4_btn1'],
                        variableUIds: []
                    }
                }
            },
            controls: {
                ids: ['scr1_layout', 'scr1_btn1', 'scr2_layout', 'scr2_btn1', 'scr3_layout', 'scr3_btn1', 'scr4_layout', 'scr4_btn1'],
                entities: {
                    scr1_layout: {
                        uid: 'scr1_layout',
                        name: 'layout',
                        controlRef: 'STACKLAYOUT',
                        childUIds: ['scr1_btn1']
                    },
                    scr1_btn1: {
                        uid: 'scr1_btn1',
                        name: 'button1',
                        controlRef: 'BUTTON',
                        text: 'Button 1!'
                    } as any,
                    scr2_layout: {
                        uid: 'scr2_layout',
                        name: 'layout',
                        controlRef: 'STACKLAYOUT',
                        childUIds: ['scr2_btn1']
                    },
                    scr2_btn1: {
                        uid: 'scr2_btn1',
                        name: 'button1',
                        controlRef: 'BUTTON'
                    },
                    scr3_layout: {
                        uid: 'scr3_layout',
                        name: 'layout',
                        controlRef: 'STACKLAYOUT',
                        childUIds: ['scr3_btn1']
                    },
                    scr3_btn1: {
                        uid: 'scr3_btn1',
                        name: 'button1',
                        controlRef: 'BUTTON'
                    },
                    scr4_layout: {
                        uid: 'scr4_layout',
                        name: 'layout',
                        controlRef: 'STACKLAYOUT',
                        childUIds: ['scr4_btn1']
                    },
                    scr4_btn1: {
                        uid: 'scr4_btn1',
                        name: 'button1',
                        controlRef: 'BUTTON'
                    }
                }
                
            }
        }
    }
});

// Infer some types from the store
type PortalDispatch = typeof portalStateStore.dispatch;
export type PortalState = ReturnType<typeof portalStateStore.getState>;

// Define some typed hooks using the infered types
export const usePortalDispatch: () => PortalDispatch = useDispatch;
export const usePortalSelector: TypedUseSelectorHook<PortalState> = useSelector;