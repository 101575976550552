import { Button, Popconfirm } from "antd";
import { GetConfigGroupsResponse } from "@appframe/configModule/getConfigGroups";
import { useConfigModule } from "@appframe/configModule/hooks/useConfigModule";
import { useNotification } from "../../../../../ui/notification";

type PublishEnvironmentConfigProps = {
    config: GetConfigGroupsResponse;
    isDraft?: boolean;
    onPublished: () => void
}

export const PublishEnvironmentConfig = ({config, isDraft, onPublished}: PublishEnvironmentConfigProps) => {
    const { showSuccess } = useNotification();
    const { publishConfigGroup } = useConfigModule();
    
    const publishConfig = async () => {
        const response = await publishConfigGroup({ groupUId: config.UId, draft: isDraft ?? false });

        if(response.HasError()) {
            
        } else {
            showSuccess('Success', 'Config published successfully');
            onPublished();
        }
    }

    const description = `Are you sure you want to publish '${config.Name}' ${isDraft ? ' draft' : ''}? This can't be undone.`
    
    return (
        <Popconfirm 
            title="Publish?" 
            description={description}
            onConfirm={() => publishConfig()}>
                <Button>{isDraft ? 'Publish Draft' : 'Publish'}</Button>
        </Popconfirm>
    )
}