import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CallScreenControl } from "../../../CallScreenControls/ControlRegistry";
import { UId } from "../../../helpers";
import { PortalState } from "../../../state/portalStateStore";

const controlsEntity = createEntityAdapter<CallScreenControl>({
    selectId: c => c.uid
});

const controlsSlice = createSlice({
    name: 'controls',
    initialState: controlsEntity.getInitialState(),
    reducers: {
        addControl: controlsEntity.addOne,
        removeControl: controlsEntity.removeOne,
        updateControl: controlsEntity.updateOne,
        addChildControl: (state, entity: PayloadAction<{parentUId: UId, childUId: UId, childIndex: number}>) => {
            const parent = state.entities[entity.payload.parentUId];

            if(!parent) {
                console.error(`controlsActions.addChildControl - unable to find parent with uid ${entity.payload.parentUId}`);
                return;
            }

            if(!parent.childUIds) {
                parent.childUIds = [];
            }

            // Add the child uid at the correct index
            parent.childUIds.splice(entity.payload.childIndex, 0, entity.payload.childUId);
        },
        addRule: (state, entity: PayloadAction<{controlUId: UId, ruleUId: UId}>) => {
            const control = state.entities[entity.payload.controlUId];

            if(!control) {
                console.error(`controlActions.addRule - unable to find parent with uid ${entity.payload.controlUId}`);
                return;
            }

            if(!control.ruleUIds) {
                control.ruleUIds = [];
            }

            control.ruleUIds.push(entity.payload.ruleUId);
        },
        addVariable: (state, entity: PayloadAction<{controlUId: UId, variableUId: UId}>) => {
            const control = state.entities[entity.payload.controlUId];

            if(!control) {
                console.error(`controlActions.addVariable - unable to find control with uid ${entity.payload.controlUId}`);
                return;
            }

            if(!control.variableUIds) control.variableUIds = [];

            control.variableUIds.push(entity.payload.variableUId);
        }
    }
});

// Selectors
const selectByIds = createSelector(
    (state: PortalState, uids: UId[]) => ({state, uids}),
    ({state, uids}) => {
        const controls: { [key: string]: CallScreenControl } = {};

        uids.map(uid => {
            const c = state.solutionManager.controls.entities[uid];
            if(c) {
                controls[uid] = c;
            }
        });

        return controls;
    }
);

export const controlsReducer = controlsSlice.reducer;

export const controlsActions = controlsSlice.actions;
export const controlsSelectors = {
    selectByIds,
    ...controlsEntity.getSelectors()
};