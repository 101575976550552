import { combineReducers } from "@reduxjs/toolkit";
import { callScreensReducer } from "../features/CallScreens/callScreensState";
import { callTypesReducer } from "../features/CallTypes/callTypeState";
import { environmentConfigReducer } from "../features/ConfigManager/environmentConfigState";
import { controlsReducer } from "../features/Controls/controlsState";
import { rulesReducer } from "../features/Rules/rulesState";
import { solutionReducer } from "../features/SolutionList/solutionState";
import { variablesReducer } from "../features/Variables/variablesState";

const reducer = combineReducers({
    solutions: solutionReducer,
    environmentConfigs: environmentConfigReducer,
    callTypes: callTypesReducer,
    callScreens: callScreensReducer,
    controls: controlsReducer,
    rules: rulesReducer,
    variables: variablesReducer
});

export default reducer;