import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Button } from "../../../ui/button/Button";

export type SimpleToastButton = {
    text: string;
    action: () => void;
    primary?: boolean
}

export type SimpleToastType = 'info' | 'success' | 'warning' | 'error';

type SimpleToastProps = {
    title: string;
    description?: string;
    type?: SimpleToastType;
    buttons?: SimpleToastButton[]
}

export const SimpleToast = ({title, description, buttons}: SimpleToastProps) => {

    const getButtons = () => {
        if(!buttons || buttons.length === 0) return <></>

        return buttons.map(b => (<Button themeType={b.primary ? 'link-primary' : 'link'} onClick={b.action}>{b.text}</Button>));
    }

    return (
        <div className="flex items-start">
            <div className="flex-shrink-0">
            <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium text-gray-900">{title}</p>
            {description && (
                <p className="mt-1 text-sm text-gray-500">{description}</p>
            )}
            {buttons && (
                <div className="mt-3 flex space-x-7">
                    {getButtons()}
                </div>
            )}
            </div>
            
            <div className="ml-4 flex flex-shrink-0">
            <button
                type="button"
                className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() =>{}}
            >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            </div>
        </div>
    )
}