import { useAuth } from "../../../auth/components/ProvideAuth"
import { PageInfo } from "../pageInfo";

const APPFRAME_URL = process.env.REACT_APP_APPFRAME_URL;

type AppFrameUrlBuildParams = {url: string, pageInfo?: PageInfo, removeToken?: boolean};
export type AppFrameUrlBuilder = (params: AppFrameUrlBuildParams) => URL;

export const useAppFrame = () => {
    const auth = useAuth();

    return {
        getUrl: ({url, pageInfo, removeToken}: {url: string, pageInfo?: PageInfo, removeToken?: boolean}) => {
            var u = new URL(url, APPFRAME_URL);

            if (pageInfo) {
                u.searchParams.set('page_no', pageInfo.page.toString());
                u.searchParams.set('items_per_page', pageInfo.pageSize.toString());
            }

            if (!removeToken && auth.user?.accessToken) {
                u.searchParams.set('token', auth.user?.accessToken);
            }

            return u;
        }
    }
}