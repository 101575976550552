import { PagedFeatureResponse, PageInfo } from "@appframe/index";
import { UId } from "@common/uid";
import { Space } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import { EnvironmentConfigTag } from "./EnvironmentConfigTag";
import { GetConfigGroupsResponse } from "@appframe/configModule/getConfigGroups";

type EnvironmentConfigsTableProps = {
    solutionUId?: UId;
    configs: PagedFeatureResponse<GetConfigGroupsResponse>
    pager: PageInfo;
    actions: (config: GetConfigGroupsResponse) => JSX.Element;
    onPageChange: (page: number) => void;
}

export const EnvironmentConfigsTable = ({ solutionUId, configs, pager, actions, onPageChange}: EnvironmentConfigsTableProps) => {

    const columns: ColumnsType<GetConfigGroupsResponse> = [{
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, group) => <Space><Link to={`/solutions/${solutionUId}/configs/${group.UId}/overview`}>{group.Name}</Link></Space> 
    }, {
        title: 'Description',
        dataIndex: 'Description',
        key: 'title'
    }, {
        title: 'Published Version',
        key: 'version',
        dataIndex: 'PublishedVersion',
        render: (version) => <EnvironmentConfigTag version={version} />
    }, {
        title: 'Draft Version',
        key: 'draftversion',
        dataIndex: 'PublishedDraftVersion',
        render: (version) => <EnvironmentConfigTag version={version} />
    }, {
        title: 'Actions',
        key: 'actions',
        dataIndex: 'UId',
        render: (_, config) =>  actions(config)
    }];

    return (
        <Table  columns={columns} 
                dataSource={configs.Results} 
                style={{height: '100%'}} 
                pagination={{pageSize: pager.pageSize, total: configs.PageCount * pager.pageSize, onChange: onPageChange, current: pager.page}} />
    );
}