import { AppFrameUrlBuilder } from "@appframe/base/hooks/useAppFrame";
import { UId } from "../../helpers";
import { postPayloadAsync } from "../base/clientBase";

type CreateConfigRequest = {
    Name: string;
    Description: string;
    Labels: string[];
}

type CreateConfigInGroupRequest = CreateConfigRequest & {
    GroupUId: UId;
}

export type CreateConfigResponse = {
    UId: UId;
    Checksum: string;
}

export type CreateConfigArgs = {
    name: string, 
    description: string, 
    labels: string[], 
    groupUId?: UId
}

export const createConfig = async (urlBuilder: AppFrameUrlBuilder, { name, description, labels, groupUId } :CreateConfigArgs) => {

    const request: CreateConfigRequest | CreateConfigInGroupRequest = groupUId ? {
        Name: name,
        Description: description,
        Labels: labels,
        GroupUId: groupUId
    } : {
        Name: name,
        Description: description,
        Labels: labels
    }

    const response = await postPayloadAsync<CreateConfigRequest | CreateConfigInGroupRequest, CreateConfigResponse>(request, {
        url: groupUId ? urlBuilder({url: `/configuration/v2/groups/${groupUId}/configs`}) : urlBuilder({url: '/configuration/v2/configs'})
    });

    return response;
}