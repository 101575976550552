import { CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";

type AlertType = 'info' | 'error' | 'success' | 'warning';

type AlertProps = {
    type: AlertType,
    message: string,
    description?: string
}

const getIcon = (type: AlertType) => {
    switch (type) {
        case 'error':
            return <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        case 'success':
            return <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
        case 'warning':
            return <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        case 'info':
        default:
            return <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
    }
}

const getBackgroundColour = (type: AlertType) => {
    switch (type) {
        case 'error':
            return 'bg-red-50 border-red-400';
        case 'success':
            return 'bg-green-50 border-green-400';
        case 'warning':
            return 'bg-yellow-50 border-yellow-400';
        case 'info':
        default:
            return 'bg-blue-50 border-blue-400'
    }
}

const getMessageColour = (type: AlertType) => {
    switch (type) {
        case 'error':
            return 'text-red-800';
        case 'success':
            return 'text-green-800';
        case 'warning':
            return 'text-yellow-800';
        case 'info':
        default:
            return 'text-blue-800'
    }
}

const getDescriptionColour = (type: AlertType) => {
    switch (type) {
        case 'error':
            return 'text-red-700';
        case 'success':
            return 'text-green-700';
        case 'warning':
            return 'text-yellow-700';
        case 'info':
        default:
            return 'text-blue-700'
    }
}

export const Alert = ({ type, message, description }: AlertProps) => {
    const backgroundClass = 'border-l-4 p-4 ' + getBackgroundColour(type);
    const messageClass = 'text-sm font-medium ' + getMessageColour(type);
    const descriptionClass = 'mt-2 text-sm ' + getDescriptionColour(type);

    return (
        <div className={backgroundClass}>
            <div className="flex">
                <div className="flex-shrink-0">
                    {getIcon(type)}
                </div>
                <div className="ml-3">
                    <h3 className={messageClass}>{message}</h3>
                    {description && (
                        <div className={descriptionClass}>
                            <p>{description}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}