import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UId } from "../../../helpers";

export interface Solution {
    uid: UId;
    name: string;
    reference: string;
    description: string;
    callTypeUIds: UId[];
}

const solutionsEntityAdapter = createEntityAdapter<Solution>({
    selectId: s => s.uid
})

const solutionsSlice = createSlice({
    name: 'solutions',
    initialState: solutionsEntityAdapter.getInitialState(),
    reducers: {
        addSolution: solutionsEntityAdapter.addOne,
        upsertSolution: solutionsEntityAdapter.upsertOne,
        addCallType: (state, action: PayloadAction<{solutionUId: UId, callTypeUId: UId}>) => {
            const solution = state.entities[action.payload.solutionUId];

            if(!solution){
                console.error("solutionActions.addCallType - Unable to find solution with uid", action.payload.solutionUId);
                return;
            }

            solution.callTypeUIds.push(action.payload.callTypeUId);
        }
    }
});

export const solutionReducer = solutionsSlice.reducer;

export const solutionActions = solutionsSlice.actions;
export const solutionSelectors = solutionsEntityAdapter.getSelectors();