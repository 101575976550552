import { EditorDevice, EditorDeviceGroup } from "../../state/callScreenEditorState";

export const defaultDevice: EditorDevice = {
    id: 'iphone11',
    name: 'iPhone 11',
    resolution: [828,1792],
    group: 'ios'
};

export const devices: Record<EditorDeviceGroup, {[key: string]: EditorDevice}> = {
    ios: {
        iphone11: defaultDevice
    },
    android: {
        generic: {
            id: 'generic',
            name: 'Some android phone',
            resolution: [500,1000],
            group: 'android'
        }
    }
}