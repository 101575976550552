import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { UId } from "../../../helpers";
import { PortalState } from "../../../state/portalStateStore";

export interface Variable {
    uid: UId;
    typeRef: string;
    name: string;
    description: string;
    defaultValue?: string;
    setByCall: boolean;
    requiredOnCall: boolean;

    dependencies?: VariableDependency[];
    variableScope: VariableScope;
}

interface VariableDependency {
    uid: UId;
    type: 'callType' | 'callScreen';
}

export interface VariableScope {
    /** Name of the scope */
    name: VariableScopeName;

    /** The UId of the scope where applicable - e.g. the uid of the questionset  */
    scopeUId: UId;
}

export enum VariableScopeName {
    /** Scoped to the device, used rarely. Mainly for getting pre-defined values rather than setting them. */
    DEVICE = 'device',

    /** Scoped to the use, probably used rarely. */
    USER = 'user',

    /** Scoped to a call. Only accessible from within that call. */
    CALL = 'call',

    /** Scoped to a screen. Only accessible from within that screen. */
    SCREEN = 'screen',

    /** Scoped to the a control. Only other rules associated with this control will have access. */
    CONTROL = 'control',

    /** Scoped to a ruleflow. Only accessible from within that ruleset - essentially used for internal logic within that rule. */
    RULEFLOW = 'ruleflow'
}

const variablesEntity = createEntityAdapter<Variable>({
    selectId: v => v.uid
});

const variablesSlice = createSlice({
    name: 'variables',
    initialState: variablesEntity.getInitialState(),
    reducers: {
        addVariable: variablesEntity.addOne
    }
});

export const variablesReducer = variablesSlice.reducer;

export const variablesActions = variablesSlice.actions;
export const variablesSelectors = {
    ...variablesEntity.getSelectors(),
    selectByIds: createSelector(
        (state: PortalState, uids: UId[]) => ({state, uids}),
        ({state, uids}) => {
            return uids.map(uid => state.solutionManager.variables.entities[uid]) as Variable[];
        }
    )
};