import { AppFrameUrlBuilder } from "@appframe/base/hooks/useAppFrame";
import { UId } from "../../helpers";
import { putPayloadAsync } from "../base/clientBase";

type UpdateConfigDataRequest = {
    Data: string;
    LatestChecksum: string;
}

type UpdateConfigDataResponse = {
    Checksum: string;
}

export type UpdateConfigDataArgs = {
    configUId: UId, 
    data: string, 
    latestChecksum: string   
}

export const updateConfigData = async(urlBuilder: AppFrameUrlBuilder, { configUId, data, latestChecksum}: UpdateConfigDataArgs) => {
    const result = await putPayloadAsync<UpdateConfigDataRequest, UpdateConfigDataResponse>({
        Data: data,
        LatestChecksum: latestChecksum
    }, {
        url: urlBuilder({ url: `/configuration/v2/configs/${configUId}` })
    });

    return result;
}